<template>
  <div class="list-filter">
    <div
      v-for="(filter, index) in options"
      :key="`list-filter-item-${index}`"
      :class="{
        'list-filter__item--disabled': disabled,
        'list-filter__item--selected': JSON.stringify(filter) === JSON.stringify(selectedFilter),
        'list-filter__item--bigger': biggerLabel
      }"
      class="list-filter__item"
      @click.prevent="chooseFilter(filter)"
    >
      {{ filter.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListFilter',

  model: {
    prop: 'selected',
    event: 'select',
  },

  props: {
    options: {
      type: Array,
      required: true,
      default: () => {},
    },

    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },

    selected: {
      type: Object,
      required: false,
      default: () => null,
    },

    biggerLabel: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  data() {
    return {
      selectedFilter: null,
    };
  },

  watch: {
    selected: {
      deep: true,
      immediate: true,
      handler(value) {
        this.selectedFilter = value;
      },
    },
  },

  methods: {
    chooseFilter(filter) {
      if (!this.disabled) {
        this.selectedFilter = filter;
        this.$emit('select', this.selectedFilter);
      }
    },
  },
};
</script>
